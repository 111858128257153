import React from "react"

import CenterColumn from "../../components/Bulma/CenterColumn"
import { Text, Image } from "../../components/Core"

const Exparel = ({ post }) => {
  if (!post.hasThisSection) return <></>

  return (
    <CenterColumn
      className="home-asird"
      leftColWidth={0}
      rightColWidth={0}
      centerColumn={
        <div className="column asird-grid">
          <Image
            data-aos="fade-right"
            data-aos-duration="1200"
            publicId={post.imageId}
          />
          <div className="hl-line"></div>
          <div data-aos="fade-left" data-aos-duration="1200">
            <Text text={post.blurb} />
            {/* <div className="button-row">
              <Button
                contained
                href={post.button.href}
                buttonText={post.button.buttonText}
              />
            </div> */}
          </div>
        </div>
      }
    />
  )
}

export default Exparel
