import React from "react"

import CenterColumn from "../../components/Bulma/CenterColumn"
import { Text, Image } from "../../components/Core"
import { Button } from "../../components/Button"

const Exparel = ({ post }) => {
  if (!post.hasThisSection) return <></>

  return (
    <CenterColumn
      className="home-exparel"
      leftColWidth={4}
      rightColWidth={4}
      centerColumn={
        <div className="column" data-aos="fade-up" data-aos-duration="1200">
          <Image publicId={post.imageId} />
          <Text text={post.blurb} />
          <Button
            href={post.button.href}
            buttonText={post.button.buttonText}
            contained
          />
        </div>
      }
    />
  )
}

export default Exparel
